// Import package from Soil plugin example.
import $ from "@modules/jquery";
function equalizeHeight(container) {
  let currentTallest = 0;
  let currentRowStart = 0;
  const rowDivs = new Array();
  let topPosition = 0;
  $(container).each(function () {
    const $el = $(this);
    $($el).height("auto");
    topPosition = $el.position().top;

    if (currentRowStart !== topPosition) {
      for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
        rowDivs[currentDiv].height(currentTallest);
      }
      rowDivs.length = 0; // empty the array
      currentRowStart = topPosition;
      currentTallest = $el.height();
      rowDivs.push($el);
    } else {
      rowDivs.push($el);
      currentTallest =
        currentTallest < $el.height() ? $el.height() : currentTallest;
    }
    for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
      rowDivs[currentDiv].height(currentTallest);
    }
  });
}

$(function () {
  // Initialize product sliders.
  const productSliders = [];
  const allProductSliders = document.querySelectorAll(
    ".ms_products_slider, .woocommerce-related-products-slider"
  );

  if (allProductSliders.length > 0) {
    for (const sliders of allProductSliders) {
      let options = null;

      if (sliders.querySelector(".product-slider")) {
        options =
          sliders.querySelector(".product-slider").dataset.sliderOptions;
        options = JSON.parse(options);

        productSliders.push({
          id: sliders.id,
          main: sliders.querySelector(".product-slider"),
          thumb: null,
          selector: "product-slider",
          options: options,
        });
      }

      if (sliders.querySelector(".product-mobile-slider")) {
        options = sliders.querySelector(".product-mobile-slider").dataset
          .sliderOptions;
        options = JSON.parse(options);

        productSliders.push({
          id: sliders.id,
          main: sliders.querySelector(".product-mobile-slider"),
          thumb: null,
          selector: "product-mobile-slider",
          options: options,
        });
      }
      if (sliders.querySelector(".related-products-slider")) {
        productSliders.push({
          id: sliders.id,
          main: sliders.querySelector(".related-products-slider"),
          thumb: null,
          selector: "related-products-slider",
          options: {
            // Default parameters
            slidesPerView: 1,
            spaceBetween: 10,
            loop: true,
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },

            breakpoints: {
              320: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            },
          },
        });
      }
    }

    productSliders.forEach((slider) => {
      new window.Swiper(
        `#${slider.id} .${slider.selector}`,
        slider.options
      );
    });
  }
});

//product slider
$(document).on("yith_wccl_product_gallery_loaded", function () {
  if ($(".woocommerce .product-image-slider").length) {
    const galleryThumbs = new window.Swiper(
      ".woocommerce .product-thumbnail-slider",
      {
        slidesPerView: 3,
        spaceBetween: "10",
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        scrollbar: {
          el: ".swiper-scrollbar",
          hide: true,
        },
        breakpoints: {
          640: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 5,
          },
          1280: {
            slidesPerView: 6,
          },
        },
      }
    );

    if ($(".woocommerce .product-thumbnail-slider").length) {
      const galleryTop = new window.Swiper(
        ".woocommerce .product-image-slider",
        {
          loop: true,
          slidesPerView: 1,
          autoHeight: true,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          thumbs: {
            swiper: galleryThumbs,
          },
        }
      );
    }

    $(".swiper-button-next, .swiper-button-prev").on("mousedown", function (e) {
      e.preventDefault();
    });
  }
});

$(handleProductFilters); // On page load.
$(document).on("yith-wcan-ajax-filtered", function () {
  handleProductFilters(); // After filtering.
});
function handleProductFilters() {
  $(".yith-wcan-filter").each(function () {
    const content = $(this).find(".filter-content.has-hidden-items");
    const items = content.find(".filter-item.toggleable");
    $(this).on("click", ".toggle.show-all", function () {
      items.toggleClass("hidden");
      $(this).toggleClass("hidden");
      $(this).parent().find(".toggle.show-less").toggleClass("hidden");
    });
    $(this)?.on("click", ".toggle.show-less", function () {
      items.toggleClass("hidden");
      $(this).toggleClass("hidden");
      $(this).parent().find(".toggle.show-all").toggleClass("hidden");
    });
  });
}
function applyEqualization() {
  const productOverview = $(".products-overview, .related-products");

    if (productOverview.length) {
      productOverview.each(function () {
        const title = $(this).find(".woocommerce-loop-product__title");
        const productMeta = $(this).find(".meta");
        equalizeHeight(title);
        equalizeHeight(productMeta);
      });
    }
}

$(document).ready(function () {
  // Initial equalization
  applyEqualization();

  // Equalize heights after all are loaded
  $(window).on('load', function() {
    applyEqualization();
  });

  // Equalize heights on window resize
  $(window).resize(function () {
    applyEqualization();
  });

  const mediaQuery = setInterval(() => {
    if (Foundation.MediaQuery.queries.length === 0) {
      return;
    }

    if (Foundation.MediaQuery.current !== "") {
      clearInterval(mediaQuery);
    }

    if (Foundation.MediaQuery.upTo("sm")) {
      const columns = document.querySelectorAll(".wp-block-column");
      columns.forEach((column) => {
        column.style.order = "1";
        if (
          column.querySelectorAll(".wp-block-cover, .wp-block-image").length > 0
        ) {
          column.style.order = "2";
        }
      });
    }
  });

  jQuery(window).on(
    "changed.zf.mediaquery",
    function (event, newSize, oldSize) {
      if (Foundation.MediaQuery.queries.length === 0) {
        return;
      }
      if (Foundation.MediaQuery.upTo("sm")) {
        const columns = document.querySelectorAll(".wp-block-column");
        columns.forEach((column) => {
          column.style.order = "1";
          if (
            column.querySelectorAll(".wp-block-cover, .wp-block-image").length >
            0
          ) {
            column.style.order = "2";
          }
        });
      } else {
        const columns = document.querySelectorAll(".wp-block-column");
        columns.forEach((column) => {
          column.style.order = "1";
        });
      }
    }
    
  );
});
  // Equalize heights after all images are loaded
  $(window).on('load', function() {
    console.log('Loaded');
    applyEqualization();
  });
  

$(window).on("resize", function () {
  applyEqualization();
  console.log('Resize');
});

$(document.body).on('added_to_cart', function(){
  requestAnimationFrame(applyEqualization);
  console.log('Yes there a product added to the cart');
});

